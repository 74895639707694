import API from "../../api/api";

export default class StudentListService {
  key = localStorage.getItem("apiKey");

  StudentList = async () => {
    return await API.API.get("studentList/studentList_tile")
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };

  statement = async (start = 0, length = 10) => {
    return await API.API.get(
      "studentList/studentListTable?length=" + length + "&start=" + start + ""
    )
      .then((res) => {
        if (res.status === 200) {
          return res.data;
        } else {
          return res;
        }
      })
      .catch((error) => console.log(error));
  };
}
