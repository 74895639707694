import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import moment from "moment";
import { Button, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
// import fileDownload from 'js-file-download';
//components
import { Tiles, Content, ModalForms } from "../../components/studentList";
import Spinner from "../../shared/Spinner";
import { useSelector } from "react-redux";

//style
import style from "../../components/studentList/ewallet.module.scss";
import Payments from "../../../service/common/PaymentApi";

//service
import StudentListService from "../../../service/studentList/studentList";

import UserStore from "../../../store/UserStore";

//data
import ewalletdata from "./ewalletdata.json";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import companyName from "../../../store/mobxStore/companyName";

const mapStateToProps = (state) => ({ lang: state.Lang });

const StudentList = (props) => {
  const Currency = useSelector((state) => state.curr);

  const { t } = props;
  const [checkout, setCheckout] = useState(false);
  // let amount = 10;
  const [EwalletAmount, setEwalletAmount] = useState({
    amount: "",
    FormError: {
      amount: "",
    },
    isvalid: false,
  });

  const [loader, setLoader] = useState({
    EarningsTable: {
      search: false,
      reset: false,
      excel: false,
      csv: false,
      print: false,
    },
    HistoryTable: {
      search: false,
      reset: false,
    },
  });

  const [locationKeys, setLocationKeys] = useState([]);
  const history = useHistory();

  const changeHandler = (e) => {
    const { name, value } = e.target;

    setEwalletAmount((prev) => ({
      ...prev,
      [name]: value,
      FormError: {
        ...prev.FormError,
        [name]: "",
      },
      isvalid: false,
    }));
  };

  const [state, setState] = useState({
    Modal: {
      fundTransfer: false,
      addWallet: false,
    },
    tilesData: [],
    balanceAmount: "",
    TransactionFee: "",
    repurchase_status: "yes",
    loading: true,
    user_type: "",
    DataTables: {
      key: UserStore.checkUserEarnings ? "earning" : "statement",
      statement: {
        totalRows: 0,
        data: [],
        pending: true,
        perPage: 10,
        inPage: 1,
      },
    },
  });
  const [clientSecret, setClientSecret] = useState("");
  const service = new StudentListService();
  const Service = new Payments();

  useEffect(() => {
    Service.createPayment(props.product, "Paypal").then((res) => {
      if (res.status) {
        setClientSecret(res.data.public_key);
      }
    });
    history.listen((location) => {
      if (history.action === "PUSH") {
        setLocationKeys([location.key]);
        if (location.pathname === "/dashboard") {
          UserStore.checkUserEarnings = false;
        }
      }
      if (history.action === "POP") {
        if (locationKeys[1] === location.key) {
          setLocationKeys(([_, ...keys]) => keys);
        } else {
          setLocationKeys((keys) => [location.key, ...keys]);
          UserStore.checkUserEarnings = false;
        }
      }
    });

    //componentdidmount
    loadData();
  }, [props, locationKeys, Currency.currentCurr]);

  const checkAvailable = () => {
    let valid = true;

    if (!EwalletAmount.amount) {
      valid = false;
      setEwalletAmount((prev) => ({
        ...prev,
        FormError: {
          ...prev.FormError,
          amount: t("validation.required", { field: "amount" }),
        },
      }));
    }
    if (valid) {
      setCheckout(true);
    }
  };

  //load the data
  const loadData = () => {
    setState((prevState) => ({
      ...prevState,
      tilesData: ewalletdata.ewallet_tile,
      balanceAmount: ewalletdata.balance,
      TransactionFee: ewalletdata.transactionFee,
      repurchase_status: ewalletdata.repurchase_status,
      purchase_wallet: ewalletdata.purchase_wallet,
      loading: false,
    }));
    //ewallet tiles
    service.StudentList().then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          tilesData: res.data.studentTiles,
          loading: false,
        }));
      } else {
        props.history.push("/logout");
      }
    });
    getstatementTable();

    // this.getHistoryTable();
  };

  //statement table
  const getstatementTable = (
    inPage = state.DataTables.statement.inPage,
    perPage = state.DataTables.statement.perPage
  ) => {
    let start = (inPage - 1) * perPage;
    service.statement(start, perPage).then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          DataTables: {
            ...prevState.DataTables,
            statement: {
              ...prevState.DataTables.statement,
              totalRows: res.count,
              data: res.table_data,
              user_type: res.user_type,
              pending: false,
            },
          },
        }));
      } else {
        props.history.push("/logout");
      }
    });
  };

  //change statement table
  const changeStatementTable = (inPage, perPage) => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        statement: {
          ...prevState.DataTables.statement,
          perPage: perPage,
          inPage: inPage,
          // pending     : false
        },
      },
    }));
    getstatementTable(inPage, perPage);
  };

  //tab change function(ddn't added this)
  const tabChangeHandler = () => {
    setState((prevState) => ({
      ...prevState,
      DataTables: {
        ...prevState.DataTables,
        statement: {
          ...prevState.DataTables.statement,
          perPage: 10,
          inPage: 1,
        },
      },
    }));

    getstatementTable();
  };

  const paginationHandler = {
    statement: changeStatementTable,
    // history: changeHistoryTable,
    // wallet: changeWalletTable,
    // earnings: changeEarningsTable,
  };
  return (
    <div className={`h-100`}>
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.ewallet")}
        </title>
        <script
          src={`https://www.paypal.com/sdk/js?client-id=${clientSecret}&currency=USD`}
        ></script>
      </Helmet>
      {/* <PageTitle title="ewallet" buttonOn={true} group={ButtonGroup} /> */}
      {state.loading ? (
        <Spinner />
      ) : (
        <div className={style.MainContainer}>
          {state.tilesData && (
            <Tiles
              tilesData={state.tilesData}
              repurchase_status={state.purchase_wallet}
            />
          )}
          <Content
            tableData={state.DataTables}
            changeHandler={tabChangeHandler}
            paginationHandler={paginationHandler}
            repurchase_status={state.purchase_wallet}
            loader={loader}
            user_type={state.DataTables.statement.user_type}
          />
        </div>
      )}
    </div>
  );
};

export default withTranslation()(
  connect(mapStateToProps)(withRouter(StudentList))
);
