import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
//service
import { CurrencyDecimalFormat } from "../../helper";
import style from "./ewallet.module.scss";
const StatementTable = React.memo(
  ({ ContentData, paginationchange, user_type }) => {
    const { t } = useTranslation();
    const Currency = useSelector((state) => state.curr);

    var columns = [
      {
        name: t("Common.studentId").toUpperCase(),
        selector: (row) => row.description,
        width: "30%",
        cell: (row) => (
          <span
            dangerouslySetInnerHTML={{ __html: row.student_id }}
            className={style.description}
          ></span>
        ),
      },
      {
        name: t("Common.commissionableAmount").toUpperCase(),
        selector: (row) => row.commissionable_value,

        cell: (row) => (
          <span className={`${row.classnameamount} ${style.badge}`}>
            {Currency.currentCurr}{" "}
            {CurrencyDecimalFormat(
              row.commissionable_value * Currency.value,
              Currency.precision
            )}
          </span>
        ),
      },
      {
        name: t("Common.date").toUpperCase(),
        selector: (row) => row.created_at,
      },
    ];

    if (user_type == "user") {
      columns.push({
        name: t("Common.affiliate").toUpperCase(),
        selector: (row) => row.description,
        width: "30%",
        cell: (row) => (
          <span
            dangerouslySetInnerHTML={{ __html: row.affiliate }}
            className={style.description}
          ></span>
        ),
      });
    } else if (user_type == "affiliate") {
      columns.push({
        name: t("Common.consultant").toUpperCase(),
        selector: (row) => row.consultant,
        width: "30%",
        cell: (row) => (
          <span
            dangerouslySetInnerHTML={{ __html: row.consultant }}
            className={style.description}
          ></span>
        ),
      });
    }

    const [state, setState] = useState({ data: [] });
    const { data } = state;
    useEffect(() => {
      let Data =
        ContentData.data &&
        ContentData.data.map((resData) => {
          return {
            classnameamount: style.credit,
            student_id: resData.student_id,
            commissionable_value: resData.commissionable_value,
            created_at: resData.created_at,
            consultant: resData?.consultant?.username
              ? resData?.consultant?.username
              : "NA",
            affiliate: !resData?.affiliate_id
              ? "NA"
              : resData?.affiliate?.username
              ? resData?.affiliate?.username
              : "NA",
          };
        });
      setState((prev) => ({
        ...prev,
        data: Data,
      }));
    }, [ContentData, setState]);

    //onchange page
    const handlePageChange = (inPage) => {
      paginationchange(inPage, ContentData.perPage);
    };
    const handlePerRowsChange = (perPage, inPage) => {
      paginationchange(inPage, perPage);
    };
    return (
      <>
        <DataTable
          columns={columns}
          data={data}
          highlightOnHover
          progressPending={ContentData.pending}
          progressComponent={<div>Loading</div>}
          persistTableHead
          noHeader
          pagination
          paginationServer
          paginationTotalRows={ContentData.totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          noDataComponent={t("Common.noDataRecordsToDisplay")}
          responsive
        />
      </>
    );
  }
);

export default StatementTable;
